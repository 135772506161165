<template>
  <div class="basic-info">
    <div class="left-info">
      <h3>材机费详情</h3>
      <div class="content">
        <div class="info-group">
          <div
            class="single-unit"
            v-for="(item, index) in basicInfo"
            :key="index"
          >
            <span>{{ item.value }}</span>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <a-divider type="vertical" />
    <div class="btn-group">
      <slot name="btnGroup"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'basicInfo',
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [
          {
            name: '材机名称',
            value: '水',
          },
          {
            name: '类别',
            value: '材料',
          },
          {
            name: '规格型号',
            value: '',
          },
          {
            name: '计量单位',
            value: 't',
          },
          {
            name: '均价(元)',
            value: '',
          },
        ];
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  methods: {},
  components: {},
});
</script>

<style lang="less" scoped>
.basic-info {
  background: #247fff url('../../../../assets/img/detail-bg.png') no-repeat;
  width: 100%;
  height: 140px;
  padding: 30px 0 20px 46px;
  color: #ffffff;
  display: flex;
  align-items: center;
  .left-info {
    width: 75%;
  }
  h3 {
    color: #ffffff;
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      left: -22px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      content: '';
      display: block;
      width: 2px;
      height: 11px;
      background: #ffffff;
    }
  }
  .content {
    display: flex;
    align-items: center;
    .info-group {
      display: flex;
      width: 90%;
      justify-content: space-between;
      .single-unit {
        span {
          display: block;
        }
        span:nth-of-type(1) {
          font-size: 28px;
        }
        span:nth-of-type(2) {
          font-size: 14px;
        }
      }
    }
  }
  .ant-divider,
  .ant-divider-vertical {
    height: 5.9em;
  }
  .btn-group {
    flex: 1;
    text-align: center;
    button + button {
      margin-left: 15px;
    }
    .ant-btn {
      width: 173px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 2px;
      border: 1px solid #ffffff;
      background-color: transparent;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
</style>
